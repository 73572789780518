import {buildSchema} from "@camberi/firecms";
import {AudioProperty} from "../properties/audio_property";
import {Track} from "../../../../definitions/definitions";
import {royaltiesProperty} from "../properties/royalties_property";

export const TrackSchema = ({roles}) => buildSchema<Track>({
    name: "Track",
    properties: {
        title: {
            title: "Title",
            validation: {required: true},
            dataType: "string"
        },
        status: {
            validation: {required: true},
            readOnly: !["ADMIN", "INTERNAL"].some(i => roles.includes(i)),
            title: "Status",
            dataType: "string",
            config: {
                previewAsTag: true,
                enumValues: {
                    PUBLISHED: "PUBLISHED",
                    UNLISTED: "UNLISTED",
                    PENDING: "PENDING"
                }
            }
        },
        bpm: {
            title: "BPM",
            validation: {
                required: true,
                requiredMessage: "You mus set a BPM between 1 and 300",
                min: 0,
                max: 1000
            },
            dataType: "number"
        },
        description: {
            title: "Description",
            dataType: "string",
            config: {
                multiline: true
            }
        },
        audio: AudioProperty,
        type: {
            validation: {required: true},
            title: "Type",
            dataType: "reference",
            path: "trackTypes",
            previewProperties: ["title", "description"]
        },
        genres: {
            dataType: "array",
            title: "Genres",
            of: {
                dataType: "reference",
                path: "genres",
                previewProperties: ["title"]
            }
        },
        moods: {
            dataType: "array",
            title: "Moods",
            of: {
                dataType: "reference",
                path: "moods",
                previewProperties: ["title"]
            }
        },
        countries: {
            dataType: "array",
            title: "Countries",
            of: {
                dataType: "reference",
                path: "countries",
                previewProperties: ["title"]
            }
        },
        instruments: {
            dataType: "array",
            title: "Instruments",
            of: {
                dataType: "reference",
                path: "instruments",
                previewProperties: ["title"]
            }
        },
        soundalikes: {
            dataType: "array",
            title: "Soundalikes",
            of: {
                dataType: "reference",
                path: "soundalikes",
                previewProperties: ["title"]
            }
        },
        credits: {
            dataType: "array",
            title: "Credits",
            validation: {required: true},
            of: {
                dataType: "map",
                properties: {
                    credit: {
                        title: "Credit/Role",
                        dataType: "reference",
                        path: "credits",
                        previewProperties: ["title"]
                    },
                    contributors: {
                        title: "Contributor/Contributors",
                        validation: {required: true},
                        dataType: "array",
                        oneOf: {
                            typeField: "type",
                            valueField: "value",
                            properties: {
                                profile: {
                                    title: "Profile",
                                    dataType: "reference",
                                    path: "profiles",
                                    previewProperties: ["title"]
                                },
                                name: {
                                    dataType: "string",
                                    title: "Name"
                                }
                            }
                        }
                    }
                }
            }
        },
        lyrics: {
            dataType: "array",
            title: "Lyrics",
            of: {
                dataType: "map",
                properties: {
                    language: {
                        title: "Langauge",
                        dataType: "reference",
                        path: "languages",
                        previewProperties: ["title"]
                    },
                    lyrics: {
                        dataType: "string",
                        title: "Lyric",
                        config: {
                            multiline: true
                        }
                    }
                }
            }
        },
        stems: {
            dataType: "array",
            title: "Stems",
            of: {
                dataType: "map",
                properties: {
                    name: {
                        required: true,
                        dataType: "string",
                        title: "name",
                    },
                    audio: AudioProperty({entityId: true})
                }
            }
        },
        versions: {
            dataType: "array",
            title: "Versions",
            of: {
                dataType: "map",
                properties: {
                    name: {
                        required: true,
                        dataType: "string",
                        title: "name",
                    },
                    audio: AudioProperty({entityId: true}),
                    royalties: {
                        ...royaltiesProperty,
                        description: "Only specify new royalty splits if they are different from the main track!"
                    }
                }
            }
        },
        royalties: {...royaltiesProperty, validation: {required: true}},
        proMetadata: {
            dataType: "array",
            title: "PRO Metadata",
            description: "ex: proISRC, proISWC, proMasterID, proWorkID, etc...",
            of: {
                dataType: "map",
                properties: {
                    type: {
                        required: true,
                        dataType: "string",
                        title: "ID Type",
                        validation: {
                            required: true
                        }
                    },
                    value: {
                        required: true,
                        dataType: "string",
                        title: "value",
                        validation: {
                            required: true
                        }
                    }
                }
            }
        },
        otherMetadata: {
            dataType: "array",
            title: "Other Metadata",
            description: "Please specify any additional or missing metadata (ex. a Genre you could not find in our system)",
            of: {
                dataType: "map",
                properties: {
                    type: {
                        required: true,
                        dataType: "string",
                        title: "Metadata Type",
                        validation: {
                            required: true
                        }
                    },
                    value: {
                        required: true,
                        dataType: "string",
                        title: "value",
                        validation: {
                            required: true
                        }
                    }
                }
            }
        },
        createdAt: {
            title: "Creation Date",
            dataType: "timestamp",
            autoValue: "on_create"
        },
        updatedAt: {
            title: "Last Updated",
            dataType: "timestamp",
            autoValue: "on_update"
        }
    },
    defaultValues: {
        status: "PENDING"
    }
})
