import {buildSchema} from "@camberi/firecms";
import {UserUploadField} from "../fields/UserUploadField";
import {GetFireCMSContext} from "../utils/db_utils";
import PdfPreview from "../previews/PdfPreview";

export const documentSchema = buildSchema({
    name: "Document",
    properties: {
        title: {
            title: "title",
            validation: {required: true},
            dataType: "string"
        },
        status: {
            readOnly: false,
            title: "Status",
            validation: {required: true},
            dataType: "string",
            config: {
                previewAsTag: true,
                enumValues: {
                    READY: "READY",
                    PENDING: "PENDING"
                }
            }
        },
        profile: {
            title: "Profile",
            dataType: "reference",
            path: "profiles",
            validation: {required: true},
            previewProperties: ["title"]
        },
        pdf: {
            title: "PDF",
            config: {
                Preview: ({value}) => PdfPreview({value: value.ref})
            },
            dataType: "map",
            properties: {
                ref: {
                    dataType: "string",
                    readOnly: true,
                    config: {
                        Preview: PdfPreview
                    }
                },
                tmpRef: {
                    validation: {required: false},
                    dataType: "string",
                    config: {
                        Field: UserUploadField,
                        storageMeta: {
                            storagePath: (context) => {
                                return `${GetFireCMSContext().authController.extra.storageDir}/tmp`;
                            },
                            metadata: {
                                'storageType': "application/pdf"
                            },
                            acceptedFiles: ["application/pdf"]
                        }
                    }
                }
            }
        },
        notes: {
            title: "Notes",
            dataType: "string"
        },
        createdAt: {
            title: "Creation Date",
            dataType: "timestamp",
            autoValue: "on_create"
        },
        updatedAt: {
            title: "Last Updated",
            dataType: "timestamp",
            autoValue: "on_update"
        }
    }
})