import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    Box,
    Button,
    IconButton, Modal,
    Popover,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {Add, Delete} from "@mui/icons-material";

import {
    AnyProperty,
    CollectionSize,
    Entity,
    EntityCollection,
    PartialEntityCollection,
    useFireCMSContext,
    useNavigation,
    useSideEntityController,
    useDataSource,
    useAuthController,
    PermissionsBuilder,
    AuthController,
    FireCMSContext,
    SchemaOverrideHandler,
    buildSchema,
} from "@camberi/firecms";
import {OnColumnResizeParams} from "@camberi/firecms";

import {Markdown} from "@camberi/firecms";
import {CollectionTable} from "./og";
import {MultiTrackUploader} from "./components/MultiTrackUpload";
import {CollectionRowActions} from "./og/core/components/CollectionTable/internal/CollectionRowActions";
import {DeleteEntityDialog} from "./og/core/components/CollectionTable/internal/DeleteEntityDialog";

/**
 * @category Components
 */
export interface EntityCollectionViewProps<M extends { [Key: string]: any }> {

    /**
     * Absolute path this collection view points to
     */
    path: string;

    /**
     * Entity collection props
     */
    collection: EntityCollection<M>;

}

export function useSelectionController<M = any>(): SelectionController {

    const [selectedEntities, setSelectedEntities] = useState<Entity<M>[]>([]);

    const toggleEntitySelection = useCallback((entity: Entity<M>) => {
        let newValue;
        if (selectedEntities.map(e => e.id).includes(entity.id)) {
            newValue = selectedEntities.filter((item: Entity<M>) => item.id !== entity.id);
        } else {
            newValue = [...selectedEntities, entity];
        }
        setSelectedEntities(newValue);
    }, [selectedEntities]);

    const isEntitySelected = useCallback((entity: Entity<M>) => selectedEntities.map(e => e.id).includes(entity.id), [selectedEntities]);

    return {
        selectedEntities,
        setSelectedEntities,
        isEntitySelected,
        toggleEntitySelection
    };
}


const DEFAULT_PERMISSIONS = {
    edit: true,
    create: true,
    delete: true
};

function checkHasPermissionOnEntity<M extends { [Key: string]: any }, UserType>
(permission: PermissionsBuilder<M, UserType> | Permissions | undefined,
 entity: Entity<M> | null,
 authController: AuthController<UserType>,
 path: string,
 context: FireCMSContext<UserType>): Permissions {

    if (permission === undefined) {
        return DEFAULT_PERMISSIONS;
    } else if (typeof permission === "object") {
        return permission as Permissions;
    } else if (typeof permission === "function") {
        return permission({
            user: authController.user,
            authController,
            entity,
            path,
            context
        });
    }

    throw Error("New type of HasPermission added and not mapped");
}

export function canEdit<M extends { [Key: string]: any }, UserType>
(permission: PermissionsBuilder<M, UserType> | Permissions | undefined,
 entity: Entity<M>,
 authController: AuthController<UserType>,
 path: string,
 context: FireCMSContext<UserType>): boolean {
    return checkHasPermissionOnEntity(permission, entity, authController, path, context).edit ?? DEFAULT_PERMISSIONS.edit;
}

export function canCreate<M extends { [Key: string]: any }, UserType>
(permission: PermissionsBuilder<M, UserType> | Permissions | undefined,
 authController: AuthController<UserType>,
 path: string,
 context: FireCMSContext<UserType>): boolean {
    return checkHasPermissionOnEntity(permission, null, authController, path, context).create ?? DEFAULT_PERMISSIONS.create;
}

export function canDelete<M extends { [Key: string]: any }, UserType>
(permission: PermissionsBuilder<M, UserType> | Permissions | undefined,
 entity: Entity<M>,
 authController: AuthController<UserType>,
 path: string,
 context: FireCMSContext<UserType>): boolean {
    return checkHasPermissionOnEntity(permission, entity, authController, path, context).delete; //&& entity.values.newApplication;
}

/**
 * This component is in charge of binding a datasource path with an {@link EntityCollection}
 * where it's configuration is defined. It includes an infinite scrolling table,
 * 'Add' new entities button,
 *
 * This component is the default one used for displaying entity collections
 * and is in charge of generating all the specific actions and customization
 * of the lower level {@link CollectionTable}
 *
 * Please **note** that you only need to use this component if you are building
 * a custom view. If you just need to create a default view you can do it
 * exclusively with config options.
 *yarn
 * If you need a lower level implementation with more granular options, you
 * can use {@link CollectionTable}.
 *
 * If you need a table that is not bound to the datasource or entities and
 * properties at all, you can check {@link Table}
 *
 * @param path
 * @param collection
 * @constructor
 * @category Components
 */
export function CustomTableView({
                                    collection,
                                    myEntities
                                }: { collection: EntityCollection, myEntities: Array<Entity<any>> }) {

    const path = collection.path;
    const sideEntityController = useSideEntityController();
    const context = useFireCMSContext();
    const navigationContext = useNavigation();
    const authController = useAuthController();
    const theme = useTheme();
    const largeLayout = useMediaQuery(theme.breakpoints.up("md"));

    const [deleteEntityClicked, setDeleteEntityClicked] = React.useState<Entity<M> | Entity<M>[] | undefined>(undefined);

    const onCollectionModifiedForUser = useCallback((partialCollection: PartialEntityCollection<any>) => {
        navigationContext.onCollectionModifiedForUser(path, partialCollection);
    }, [path]);

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [modalLoading, setModalLoading] = React.useState(false);
    const [openModal, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => !modalLoading && setOpen(false);
    const modalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const selectionController = useSelectionController<M>();
    const usedSelectionController = collection.selectionController ?? selectionController;
    const {
        selectedEntities,
        toggleEntitySelection,
        isEntitySelected,
        setSelectedEntities
    } = usedSelectionController;

    useEffect(() => {
        setDeleteEntityClicked(undefined);
    }, [selectedEntities]);

    const onEntityClick = useCallback((entity: Entity<any>) => {
        return sideEntityController.open({
            entityId: entity.id,
            path: path,
            permissions: collection.permissions,
            schema: collection.schema,
            subcollections: collection.subcollections,
            callbacks: collection.callbacks
        });
    }, [path, collection, sideEntityController]);

    const onNewClick = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        return sideEntityController.open({
            path: path,
            permissions: collection.permissions,
            schema: collection.schema,
            subcollections: collection.subcollections,
            callbacks: collection.callbacks
        });
    }, [path, collection, sideEntityController]);

    const internalOnEntityDelete = useCallback((_path: string, entity: Entity<M>) => {
        setSelectedEntities(selectedEntities.filter((e) => e.id !== entity.id));
    }, [selectedEntities, setSelectedEntities]);

    const internalOnMultipleEntitiesDelete = useCallback((_path: string, entities: Entity<M>[]) => {
        setSelectedEntities([]);
        setDeleteEntityClicked(undefined);
    }, [setSelectedEntities]);

    const collectionResolver = navigationContext.getCollectionResolver(path);
    const {schemaResolver} = collectionResolver;

    const onColumnResize = useCallback(({
                                            width,
                                            key
                                        }: OnColumnResizeParams) => {
        // Only for property columns
        if (!collection.schema.properties[key]) return;
        const property: Partial<AnyProperty> = {columnWidth: width};
        const updatedFields: PartialEntityCollection<any> = {schema: {properties: {[key as keyof any]: property}}};
        if (onCollectionModifiedForUser)
            onCollectionModifiedForUser(updatedFields)
    }, [collection.schema.properties, onCollectionModifiedForUser]);

    const onSizeChanged = useCallback((size: CollectionSize) => {
        if (onCollectionModifiedForUser)
            onCollectionModifiedForUser({defaultSize: size})
    }, [onCollectionModifiedForUser]);

    const open = anchorEl != null;
    const title = useMemo(() => (
        <div style={{
            padding: "4px"
        }}>

            <Typography
                variant="h6"
                style={{
                    lineHeight: "1.0",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    maxWidth: "160px",
                    cursor: collection.description ? "pointer" : "inherit"
                }}
                onClick={collection.description
                    ? (e) => {
                        setAnchorEl(e.currentTarget);
                        e.stopPropagation();
                    }
                    : undefined}
            >
                {`${collection.name}`}
            </Typography>
            <Typography
                style={{
                    display: "block",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    maxWidth: "160px",
                    direction: "rtl",
                    textAlign: "left"
                }}
                variant={"caption"}
                color={"textSecondary"}>
                {`/${path}`}
            </Typography>

            {collection.description &&
                <Popover
                    id={"info-dialog"}
                    open={open}
                    anchorEl={anchorEl}
                    elevation={1}
                    onClose={() => {
                        setAnchorEl(null);
                    }}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                >

                    <Box m={2}>
                        <Markdown source={collection.description}/>
                    </Box>

                </Popover>
            }

        </div>
    ), [collection.description, collection.name, path, open, anchorEl]);

    const tableRowActionsBuilder = useCallback(({
                                                    entity,
                                                    size
                                                }: { entity: Entity<any>, size: CollectionSize }) => {

        const createEnabled = canCreate(collection.permissions, authController, path, context);
        const editEnabled = canEdit(collection.permissions, entity, authController, path, context);
        const deleteEnabled = canDelete(collection.permissions, entity, authController, path, context);
        const isSelected = isEntitySelected(entity);

        const onCopyClicked = (clickedEntity: Entity<M>) => sideEntityController.open({
            entityId: clickedEntity.id,
            path,
            copy: true,
            permissions: {
                edit: editEnabled,
                create: createEnabled,
                delete: deleteEnabled
            },
            schema: collection.schema,
            subcollections: collection.subcollections,
            callbacks: collection.callbacks,
            overrideSchemaRegistry: false
        });

        const onEditClicked = (clickedEntity: Entity<M>) => sideEntityController.open({
            entityId: clickedEntity.id,
            path,
            permissions: {
                edit: editEnabled,
                create: createEnabled,
                delete: deleteEnabled
            },
            schema: collection.schema,
            subcollections: collection.subcollections,
            callbacks: collection.callbacks,
            overrideSchemaRegistry: false
        });

        return (
            <CollectionRowActions
                entity={entity}
                isSelected={isSelected}
                selectionEnabled={false}
                size={size}
                toggleEntitySelection={toggleEntitySelection}
                onEditClicked={onEditClicked}
                onCopyClicked={undefined}
                onDeleteClicked={deleteEnabled ? setDeleteEntityClicked : undefined}
            />
        );

    }, [usedSelectionController, sideEntityController, collection.permissions, authController, path]);

    const toolbarActionsBuilder = useCallback((_: { size: CollectionSize, data: Entity<any>[] }) => {

        const addButton = canCreate(collection.permissions, authController, path, context) && onNewClick && (largeLayout
            ? <Button
                onClick={onNewClick}
                startIcon={<Add/>}
                size="large"
                variant="contained"
                color="primary">
                Add {collection.schema.name}
            </Button>
            : <Button
                onClick={onNewClick}
                size="medium"
                variant="contained"
                color="primary"
            >
                <Add/>
            </Button>);

        const extraActions = collection.extraActions
            ? collection.extraActions({
                path,
                collection,
                context
            })
            : undefined;

        return (
            <>
                {extraActions}
                {addButton}
                {collection.schema.name == "Track" && <Button
                    onClick={handleOpen}
                    startIcon={<Add/>}
                    size="large"
                    variant="contained"
                    color="info">
                    Bulk Upload
                </Button>}
            </>
        );
    }, [path, collection, largeLayout]);

    return (
        <>
            <Modal
                open={openModal}
                backdrop="static"
                disableEscapeKeyDown={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Multi Track Upload
                    </Typography>
                    <Typography id="modal-modal-description" sx={{mt: 2}}>
                        Every WAV file you submit will become a new track ready for metadata input.
                    </Typography>
                    <MultiTrackUploader loading={modalLoading} setLoading={setModalLoading}/>
                    <Button disabled={modalLoading}
                            style={{
                                marginTop: "2rem"
                            }}
                            onClick={handleClose}
                            size="small"
                            variant="outlined"
                            color="primary">Cancel</Button>
                </Box>
            </Modal>
            <CollectionTable
                key={`collection_table_${path}`}
                entitiesDisplayedFirst={myEntities}
                title={title}
                path={path}
                collection={collection}
                schemaResolver={schemaResolver}
                onSizeChanged={onSizeChanged}
                inlineEditing={false}
                tableRowActionsBuilder={tableRowActionsBuilder}
                onEntityClick={onEntityClick}
                onColumnResize={onColumnResize}
                toolbarActionsBuilder={toolbarActionsBuilder}
                hoverRow={false}
            />

            {deleteEntityClicked && <DeleteEntityDialog entityOrEntitiesToDelete={deleteEntityClicked}
                                                        path={path}
                                                        schema={collection.schema}
                                                        schemaResolver={schemaResolver}
                                                        callbacks={collection.callbacks}
                                                        open={!!deleteEntityClicked}
                                                        onEntityDelete={internalOnEntityDelete}
                                                        onMultipleEntitiesDelete={internalOnMultipleEntitiesDelete}
                                                        onClose={() => setDeleteEntityClicked(undefined)}/>}
        </>
    );
}
