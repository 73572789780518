import {buildProperty} from "@camberi/firecms";
import ImagePreview from "../previews/ImagePreview";
import {UserUploadField} from "../fields/UserUploadField";
import {GetFireCMSContext} from "../utils/db_utils";

export const imageProperty = buildProperty({
    title: "Picture",
    dataType: "map",
    required: true,
    disabled: false,
    properties: {
        original: {
            config: {
                Preview: ImagePreview,
            },
            dataType: "map",
            properties: {
                downloadURL: {
                    dataType: "string",
                    readOnly: true,
                    config: {
                        Preview: ({value}) => ImagePreview({value: {downloadURL: value}})
                    }
                },
                tmpRef: {
                    dataType: "string",
                    config: {
                        Field: UserUploadField,
                        storageMeta: {
                            storagePath: (context) => {
                                return `${GetFireCMSContext().authController.extra.storageDir}/tmp`;
                            },
                            metadata: {
                                'storageType': "image/jpeg"
                            },
                            acceptedFiles: ["image/jpeg"]
                        }
                    }
                }
            }
        }
    }
})