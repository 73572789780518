import {doc} from "firebase/firestore";
import {FireCMSContext, useFireCMSContext} from "@camberi/firecms";

export const relsToRefs = (db, fieldData, collection) => {
    return fieldData ? Object.values(fieldData).map(c => {
        return doc(db, collection, c.id)
    }) : [];
}

export function GetFireCMSContext(): FireCMSContext<any> {
    return useFireCMSContext()
}

export function getRandomId() {
    return Math.floor(Math.random() * Math.floor(Number.MAX_SAFE_INTEGER));
}

