import {buildCollection, buildProperty} from "@camberi/firecms";
import {TrackSchema} from "../schemas/track_schema";
import {defaultCollectionCallbacks} from "../utils/collection_utils";

export const tracksCollection = ({roles}) => buildCollection({
    path: "tracks",
    schema: TrackSchema({roles}),
    name: "Tracks",
    group: "Library",
    textSearchEnabled: true,
    //extraActions: TrackExtraActionBuilder,
    selectionEnabled: false,
    defaultSize: "m",
    callbacks: defaultCollectionCallbacks,
    inlineEditing: false,
    properties: ["ADMIN", "INTERNAL"].some(i => roles.includes(i)) ? false : ["title"],
    permissions: ({authController}) => ({
        edit: ["ADMIN", "INTERNAL"].some(i => authController.extra.roles.includes(i)),
        create: ["ADMIN", "INTERNAL"].some(i => authController.extra.roles.includes(i)),
        delete: ["ADMIN", "INTERNAL"].some(i => authController.extra.roles.includes(i))
    })
})


