import {useCallback, useEffect, useMemo, useState} from "react";
import {Entity, EntitySchemaResolver, FilterValues, useDataSource} from "@camberi/firecms";
import {usersSchema} from "../../../schemas/users_schema";
import {collection} from "firebase/firestore";

/**
 * @category Hooks and utilities
 */
export interface CollectionFetchProps<M extends { [Key: string]: any }> {

    /**
     * Absolute collection path
     */
    path: string;

    /**
     * Schema of the entity displayed by this collection
     */
    schemaResolver: EntitySchemaResolver<M>;

    /**
     * Number of entities to fetch
     */
    itemCount?: number;

    /**
     * List of entities that will be displayed on top, no matter the ordering.
     * This is used for reference fields selection
     */
    entitiesDisplayedFirst?: Entity<M>[];

    /**
     * Filter the fetched data by the property
     */
    filterValues?: FilterValues<M>;

    /**
     * Sort the results by
     */
    sortBy?: [Extract<keyof M, string>, "asc" | "desc"];

    /**
     * Search string
     */
    searchString?: string;
}

/**
 * @category Hooks and utilities
 */
export interface CollectionFetchResult<M extends { [Key: string]: any }> {
    data: Entity<M>[]
    dataLoading: boolean,
    noMoreToLoad: boolean,
    dataLoadingError?: Error
}

/**
 * This hook is used to fetch collections using a given schema
 * @param path
 * @param schemaResolver
 * @param filterValues
 * @param sortBy
 * @param itemCount
 * @param searchString
 * @param entitiesDisplayedFirst
 * @category Hooks and utilities
 */
export function useCollectionFetch<M>(
    {
        path,
        schemaResolver,
        filterValues,
        sortBy,
        itemCount,
        searchString,
        entitiesDisplayedFirst
    }: CollectionFetchProps<M>): CollectionFetchResult<M> {

    const sortByProperty = sortBy ? sortBy[0] : undefined;
    const currentSort = sortBy ? sortBy[1] : undefined;

    const dataSource = useDataSource();
    const [data, setData] = useState<Entity<M>[]>();
    const [dataLoading, setDataLoading] = useState<boolean>(false);
    const [dataLoadingError, setDataLoadingError] = useState<Error | undefined>();
    const [noMoreToLoad, setNoMoreToLoad] = useState<boolean>(false);
    let entities: Entity<M>[] = entitiesDisplayedFirst;

    const updateData = useCallback((entities: Entity<M>[]) => {
        setData(entities.filter(e => e.values));
    }, []);

    useEffect(() => {

        if (entities.length > 0) {
            setDataLoading(true);
        }

        const onEntityUpdate = (entity: Entity<M>) => {
            for (let i = 0; i < entities.length; i++) {
                if (entities[i].id === entity.id) {
                    entities[i] = entity
                }
            }

            setDataLoading(false);
            setDataLoadingError(undefined);
            updateData(entities);
            setNoMoreToLoad(true);
        };

        const onError = (error: Error) => {
            console.error("ERROR", error);
            setDataLoading(false);
            setData([]);
            setDataLoadingError(error);
        };

        for (let e of entities) {
            dataSource.listenEntity({
                path: path,
                entityId: e.id,
                schema: schemaResolver,
                onUpdate: onEntityUpdate,
                onError
            })
        }

        return;
    }, [path, itemCount, currentSort, sortByProperty, filterValues, searchString]);

    return {
        data,
        dataLoading,
        dataLoadingError,
        noMoreToLoad
    };

}
