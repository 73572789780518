import {buildSchema} from "@camberi/firecms";

export const faqSchema = buildSchema({
    name: "FAQ",
    properties: {
        question: {
            title: "Question",
            validation: {required: true},
            dataType: "string",
        },
        order: {
            title: "Order",
            validation: {required: true},
            dataType: "number",
        },
        answer: {
            title: "Answer",
            validation: {required: true},
            dataType: "string",
            config: {
                multiline: true
            }
        },
        categories: {
            dataType: "array",
            title: "Categories",
            of: {
                title: "Status",
                validation: {required: true},
                dataType: "string",
                config: {
                    enumValues: {
                        ARTIST: "ARTIST",
                        CLIENT: "CLIENT"
                    }
                }
            }
        },
        status: {
            title: "Status",
            validation: {required: true},
            dataType: "string",
            config: {
                enumValues: {
                    PUBLISHED: "PUBLISHED",
                    PENDING: "PENDING"
                }
            }
        },
        createdAt: {
            title: "Creation Date",
            dataType: "timestamp",
            autoValue: "on_create"
        },
        updatedAt: {
            title: "Last Updated",
            dataType: "timestamp",
            autoValue: "on_update"
        }
    }
});
