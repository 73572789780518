import {PreviewComponentProps, useSnackbarController, useStorageSource} from "@camberi/firecms";
import LoadingButton from "@mui/lab/LoadingButton";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import React from "react";

export default function PdfPreview({value, fileName}: PreviewComponentProps<string>) {
    const storage = useStorageSource();
    const snackbar = useSnackbarController()
    const [loading, setLoading] = React.useState<boolean>(false);

    let download = (e) => {
        setLoading(true)
        e.preventDefault()
        e.stopPropagation()

        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onloadstart = (event) => {
            snackbar.open({
                message: "Preparing file, this may take a minute..."
            })
        }
        xhr.onerror = (event) => {
            snackbar.open({
                type: "error",
                message: "Failed to download the file, please try again later..."
            })
            setLoading(false)
        }
        xhr.onload = (event) => {
            const blob = xhr.response;

            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";

            var url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = fileName ? fileName + ".pdf" : "Chai_time_document.pdf";
            a.click();
            window.URL.revokeObjectURL(url);
            setLoading(false)
        };

        if (value.startsWith('http')) {
            xhr.open('GET', value);
            xhr.send();
            return
        } else {
            storage.getDownloadURL(value)
                .then((url) => {
                    xhr.open('GET', url);
                    xhr.send();
                });
        }
    }

    return value && <LoadingButton loading={loading} onClick={download}><PictureAsPdfIcon/> Download PDF</LoadingButton>
}
