export const royaltiesProperty = {
    dataType: "array",
    title: "Royalties",
    of: {
        dataType: "map",
        properties: {
            proaccount: {
                required: true,
                title: "PRO account",
                dataType: "reference",
                path: "proaccounts",
                previewProperties: ["title"]
            },
            role: {
                title: "Role",
                dataType: "string",
                config: {
                    previewAsTag: true,
                    enumValues: {
                        PUBLISHER: "PUBLISHER",
                        WRITER: "WRITER"
                    }
                }
            },
            percentage: {
                title: "Percentage",
                dataType: "number",
                validation: {
                    min: 0,
                    max: 100
                }
            }
        }
    }
}