import algoliasearch, {SearchClient} from "algoliasearch";

import {
    FirestoreTextSearchController,
    performAlgoliaTextSearch
} from "@camberi/firecms";

let client: SearchClient | undefined = undefined;

if (process.env && process.env.REACT_APP_ALGOLIA_APP_ID && process.env.REACT_APP_ALGOLIA_SEARCH_KEY) {
    client = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_SEARCH_KEY);
}

export const textSearchController: FirestoreTextSearchController =
    ({path, searchString}) => {

        if (!client) {
            return undefined
        }

        console.log(path)

        const index = client.initIndex(path)

        return index && performAlgoliaTextSearch(index, searchString);
    };
