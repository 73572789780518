import {buildCollection} from "@camberi/firecms";
import {faqSchema} from "../schemas/faq_schema";
import {defaultCollectionCallbacks} from "../utils/collection_utils";

export const faqCollection = ({roles}) => buildCollection({
    path: "faq",
    schema: faqSchema,
    name: "FAQ",
    group: "Site",
    textSearchEnabled: true,
    selectionEnabled: false,
    callbacks: defaultCollectionCallbacks,
    inlineEditing: false,
    properties: ["ADMIN", "INTERNAL"].some(i => roles.includes(i)) ? false : ["question", "answer"],
    permissions: ({authController}) => ({
        edit: ["ADMIN", "INTERNAL"].some(i => authController.extra.roles.includes(i)),
        create: ["ADMIN", "INTERNAL"].some(i => authController.extra.roles.includes(i)),
        delete: ["ADMIN", "INTERNAL"].some(i => authController.extra.roles.includes(i))
    })
})

