import {PreviewComponentProps, useSnackbarController} from "@camberi/firecms";
import * as React from "react";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import LoadingButton from '@mui/lab/LoadingButton';

export default function AudioPreview({value, fileName}: PreviewComponentProps<string>) {
    const snackbar = useSnackbarController()

    const [loading, setLoading] = React.useState<boolean>(false);

    if (!value) {
        return <span>Preview will be available here after the track is saved.</span>
    }

    let url

    if (value.tmpDownloadUrl) {
        url = value.tmpDownloadUrl
    } else if (value.downloadURL) {
        url = value.downloadURL

        if (!url.includes("?")) {
            url += `?v=${value.updatedAt ? value.updatedAt.getTime() : Date.now()}`
        }
    }

    let download = (e) => {
        setLoading(true)
        e.preventDefault()
        e.stopPropagation()

        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onloadstart = (event) => {
            snackbar.open({
                message: "Preparing file, this may take a minute..."
            })
        }
        xhr.onerror = (event) => {
            snackbar.open({
                type: "error",
                message: "Failed to download the file, please try again later..."
            })
            setLoading(false)
        }
        xhr.onload = (event) => {
            const blob = xhr.response;

            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";

            var url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = fileName ? fileName + ".wav" : "Chai_time_track_download.wav";
            a.click();
            window.URL.revokeObjectURL(url);
            setLoading(false)
        };
        xhr.open('GET', url);
        xhr.send();
    }

    return url && <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
        <audio controls controlsList="nodownload" style={{marginRight: "5px"}} src={url} preload="none"/>
        <LoadingButton loading={loading} onClick={download}><SaveAltIcon/></LoadingButton></div>
}
