import {buildSchema} from "@camberi/firecms";

export const directorySchema = buildSchema({
    name: "PRO account",
    properties: {
        title: {
            title: "Recipient Name",
            validation: {required: true},
            description: "Must match the name in the PRO registration",
            dataType: "string",
        },
        ipi: {
            title: "IPI",
            validation: {required: true},
            dataType: "string",
            description: "Interested Party Information number"
        },
        profile: {
            title: "Profile",
            dataType: "reference",
            path: "profiles",
            disabled: {
                hidden: true
            },
            validation: {required: false},
            previewProperties: ["title"]
        },
        pro: {
            title: "PRO",
            validation: {required: true},
            dataType: "string",
            description: "Performing Rights Organization"
        },
        usage: {
            title: "Usage",
            validation: {required: true},
            dataType: "string",
            config: {
                enumValues: {
                    PUBLISHING: "PUBLISHING",
                    WRITING: "WRITING"
                }
            }
        },
        createdAt: {
            title: "Creation Date",
            dataType: "timestamp",
            autoValue: "on_create"
        },
        updatedAt: {
            title: "Last Updated",
            dataType: "timestamp",
            autoValue: "on_update"
        }
    }
});
