import {buildSchema} from "@camberi/firecms";

export const usersSchema = buildSchema({
    name: "User",
    properties: {
        email: ({values, entityId}) => {
            return {
                title: "Email",
                dataType: "string",
                email: true,
                validation: {
                    required: true,
                    email: true
                },
                readOnly: entityId
            }
        },
        firstName: {
            title: "First name",
            dataType: "string",
            editable: false
        },
        lastName: {
            title: "Last Name",
            dataType: "string"
        },
        passwordResetSent: {
            title: "Welcome Sent",
            dataType: "boolean",
            readOnly: true
        },
        tracks: {
            title: "Tracks",
            dataType: "array",
            description: "Tracks this user manages",
            of: {
                dataType: "reference",
                path: "tracks",
                previewProperties: ["title"]
            }
        },
        profiles: {
            dataType: "array",
            title: "Profiles",
            description: "Profiles this user manages",
            of: {
                dataType: "reference",
                path: "profiles",
                previewProperties: ["title"]
            }
        },
        proaccounts: {
            dataType: "array",
            title: "PRO accounts",
            of: {
                dataType: "reference",
                path: "proaccounts",
                previewProperties: ["title"]
            }
        },
        documents: {
            dataType: "array",
            title: "Documents",
            of: {
                dataType: "reference",
                path: "documents",
                previewProperties: ["title"]
            },
            readOnly: true
        },
        createdAt: {
            title: "Creation Date",
            dataType: "timestamp",
            autoValue: "on_create"
        },
        updatedAt: {
            title: "Last Updated",
            dataType: "timestamp",
            autoValue: "on_update"
        }
    }
});
