export const googleIcon = (mode: "light" | "dark") => <>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
        width={32}
        height={32}
    >
        <linearGradient
            id="95yY7w43Oj6n2vH63j6HJb"
            x1="29.401"
            x2="29.401"
            y1="4.064"
            y2="106.734"
            gradientTransform="matrix(1 0 0 -1 0 66)"
            gradientUnits="userSpaceOnUse"
        >
            <stop offset="0" stopColor="#ff5840"/>
            <stop offset=".007" stopColor="#ff5840"/>
            <stop offset=".989" stopColor="#fa528c"/>
            <stop offset="1" stopColor="#fa528c"/>
        </linearGradient>
        <path
            fill="url(#95yY7w43Oj6n2vH63j6HJb)"
            d="M47.46,15.5l-1.37,1.48c-1.34,1.44-3.5,1.67-5.15,0.6c-2.71-1.75-6.43-3.13-11-2.37 c-4.94,0.83-9.17,3.85-11.64, 7.97l-8.03-6.08C14.99,9.82,23.2,5,32.5,5c5,0,9.94,1.56,14.27,4.46 C48.81,10.83,49.13,13.71,47.46,15.5z"
        />
        <linearGradient
            id="95yY7w43Oj6n2vH63j6HJc"
            x1="12.148"
            x2="12.148"
            y1=".872"
            y2="47.812"
            gradientTransform="matrix(1 0 0 -1 0 66)"
            gradientUnits="userSpaceOnUse"
        >
            <stop offset="0" stopColor="#feaa53"/>
            <stop offset=".612" stopColor="#ffcd49"/>
            <stop offset="1" stopColor="#ffde44"/>
        </linearGradient>
        <path
            fill="url(#95yY7w43Oj6n2vH63j6HJc)"
            d="M16.01,30.91c-0.09,2.47,0.37,4.83,1.27,6.96l-8.21,6.05c-1.35-2.51-2.3-5.28-2.75-8.22 c-1.06-6.88,0.54-13.38, 3.95-18.6l8.03,6.08C16.93,25.47,16.1,28.11,16.01,30.91z"
        />
        <linearGradient
            id="95yY7w43Oj6n2vH63j6HJd"
            x1="29.76"
            x2="29.76"
            y1="32.149"
            y2="-6.939"
            gradientTransform="matrix(1 0 0 -1 0 66)"
            gradientUnits="userSpaceOnUse"
        >
            <stop offset="0" stopColor="#42d778"/>
            <stop offset=".428" stopColor="#3dca76"/>
            <stop offset="1" stopColor="#34b171"/>
        </linearGradient>
        <path
            fill="url(#95yY7w43Oj6n2vH63j6HJd)"
            d="M50.45,51.28c-4.55,4.07-10.61,6.57-17.36,6.71C22.91,58.2,13.66,52.53,9.07,43.92l8.21-6.05 C19.78,43.81, 25.67,48,32.5,48c3.94,0,7.52-1.28,10.33-3.44L50.45,51.28z"
        />
        <linearGradient
            id="95yY7w43Oj6n2vH63j6HJe"
            x1="46"
            x2="46"
            y1="3.638"
            y2="35.593"
            gradientTransform="matrix(1 0 0 -1 0 66)"
            gradientUnits="userSpaceOnUse"
        >
            <stop offset="0" stopColor="#155cde"/>
            <stop offset=".278" stopColor="#1f7fe5"/>
            <stop offset=".569" stopColor="#279ceb"/>
            <stop offset=".82" stopColor="#2cafef"/>
            <stop offset="1" stopColor="#2eb5f0"/>
        </linearGradient>
        <path
            fill="url(#95yY7w43Oj6n2vH63j6HJe)"
            d="M59,31.97c0.01,7.73-3.26,14.58-8.55,19.31l-7.62-6.72c2.1-1.61,3.77-3.71,4.84-6.15
        c0.29-0.66-0.2-1.41-0.92-1.41H37c-2.21,0-4-1.79-4-4v-2c0-2.21,1.79-4,4-4h17C56.75,27,59,29.22,59,31.97z"
        />
    </svg>
</>;

export const appleIcon = (mode: "light" | "dark") => <svg width={32} height={32}
                                                          viewBox="0 0 56 56"
                                                          style={{transform: "scale(2.8)"}}
                                                          version="1.1"
                                                          xmlns="http://www.w3.org/2000/svg">
    <g stroke={mode === "light" ? "#424245" : "white"} strokeWidth="0.5"
       fillRule="evenodd">
        <path
            d="M28.2226562,20.3846154 C29.0546875,20.3846154 30.0976562,19.8048315 30.71875,19.0317864 C31.28125,18.3312142 31.6914062,17.352829 31.6914062,16.3744437 C31.6914062,16.2415766 31.6796875,16.1087095 31.65625,16 C30.7304687,16.0362365 29.6171875,16.640178 28.9492187,17.4494596 C28.421875,18.06548 27.9414062,19.0317864 27.9414062,20.0222505 C27.9414062,20.1671964 27.9648438,20.3121424 27.9765625,20.3604577 C28.0351562,20.3725366 28.1289062,20.3846154 28.2226562,20.3846154 Z M25.2929688,35 C26.4296875,35 26.9335938,34.214876 28.3515625,34.214876 C29.7929688,34.214876 30.109375,34.9758423 31.375,34.9758423 C32.6171875,34.9758423 33.4492188,33.792117 34.234375,32.6325493 C35.1132812,31.3038779 35.4765625,29.9993643 35.5,29.9389701 C35.4179688,29.9148125 33.0390625,28.9122695 33.0390625,26.0979021 C33.0390625,23.6579784 34.9140625,22.5588048 35.0195312,22.474253 C33.7773438,20.6382708 31.890625,20.5899555 31.375,20.5899555 C29.9804688,20.5899555 28.84375,21.4596313 28.1289062,21.4596313 C27.3554688,21.4596313 26.3359375,20.6382708 25.1289062,20.6382708 C22.8320312,20.6382708 20.5,22.5950413 20.5,26.2911634 C20.5,28.5861411 21.3671875,31.013986 22.4335938,32.5842339 C23.3476562,33.9129053 24.1445312,35 25.2929688,35 Z"
            fill={mode === "light" ? "#424245" : "white"} fillRule="nonzero"/>
    </g>
</svg>;

export const githubIcon = (mode: "light" | "dark") => <svg
    fill={mode === "light" ? "#1c1e21" : "white"}
    role="img"
    viewBox="0 0 24 24"
    width={28}
    height={28}
    xmlns="http://www.w3.org/2000/svg">
    <path
        d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12"/>
</svg>;


export const facebookIcon = (mode: "light" | "dark") => <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28} height={28}
    viewBox="0 0 90 90">
    <g>
        <path
            d="M90,15.001C90,7.119,82.884,0,75,0H15C7.116,0,0,7.119,0,15.001v59.998   C0,82.881,7.116,90,15.001,90H45V56H34V41h11v-5.844C45,25.077,52.568,16,61.875,16H74v15H61.875C60.548,31,59,32.611,59,35.024V41   h15v15H59v34h16c7.884,0,15-7.119,15-15.001V15.001z"
            fill={mode === "light" ? "#39569c" : "white"}/>
    </g>
</svg>;

export const microsoftIcon = (mode: "light" | "dark") => <svg
    xmlns="http://www.w3.org/2000/svg" width={28} height={28}
    viewBox="0 0 480 480">
    <g>
        <path
            d="M0.176,224L0.001,67.963l192-26.072V224H0.176z M224.001,37.241L479.937,0v224H224.001V37.241z M479.999,256l-0.062,224   l-255.936-36.008V256H479.999z M192.001,439.918L0.157,413.621L0.147,256h191.854V439.918z"
            fill={mode === "light" ? "#00a2ed" : "white"}/>
    </g>
</svg>;

export const twitterIcon = (mode: "light" | "dark") => <svg
    xmlns="http://www.w3.org/2000/svg" width={28} height={28}
    viewBox="0 0 24 24">
    <path fill={mode === "light" ? "#00acee" : "white"}
          d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/>
</svg>;

