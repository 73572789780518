import {buildSchema} from "@camberi/firecms";
import {imageProperty} from "../properties/image_propery";
import {UserUploadField} from "../fields/UserUploadField";
import {GetFireCMSContext} from "../utils/db_utils";
import React from "react";
import {SelectWithPreview} from "../fields/SelectWithPreview";

export const profilesSchema = ({roles}) => buildSchema({
    name: "Profile",
    properties: {
        title: {
            title: "Title",
            validation: {required: true},
            dataType: "string"
        },
        status: {
            title: "Status",
            validation: {required: true},
            dataType: "string",
            config: {
                enumValues: {
                    PUBLISHED: "PUBLISHED",
                    PENDING: "PENDING"
                }
            }
        },
        isTalent: {
            title: "Show in talent page",
            disabled: !["ADMIN", "INTERNAL"].some(i => roles.includes(i)) && {
                hidden: !["ADMIN", "INTERNAL"].some(i => roles.includes(i))
            },
            dataType: "boolean"
        },
        credits: {
            dataType: "array",
            validation: {required: false},
            title: "Talent Page Categories",
            disabled: !["ADMIN", "INTERNAL"].some(i => roles.includes(i)) && {
                hidden: !["ADMIN", "INTERNAL"].some(i => roles.includes(i))
            },
            of: {
                dataType: "string",
                config: {
                    enumValues: {
                        Producer: "Producer",
                        Songwriter: "Songwriter",
                        Composer: "Composer"
                    }
                }
            }
        },
        /*agreement: ({entityId}) => {
            if(entityId) {
                return {
                    title: "Publishing agreement",
                    dataType: "map",
                    readOnly: true,
                    properties: {
                        status: {
                            title: "Status",
                            dataType: "string"
                        }
                    }
                }
            }

            return {
                title: "Library Agreement",
                dataType: "map",
                properties: {
                    type: {
                        title: "Applicant",
                        validation: { required: true },
                        dataType: "string",
                        config: {
                            Field: SelectWithPreview,
                            enumValues: {
                                ARTIST: "ARTIST",
                                MANAGER: "MANAGER",
                                PUBLISHER: "PUBLISHER"
                            },
                        },
                    },
                    tmpRef: {
                        dataType: "string",
                        validation: {required: true},
                        title: "UPLOAD SIGNED PDF BELOW:",
                        config: {
                            Field: UserUploadField,
                            storageMeta: {
                                storagePath: (context) => {
                                    return `${GetFireCMSContext().authController.extra.storageDir}/tmp`;
                                },
                                metadata: {
                                    'storageType': "application/pdf"
                                },
                                acceptedFiles: ["application/pdf"]
                            }
                        }
                    }
                }
            }
        },*/
        about: {
            title: "About",
            description: "Not mandatory but it'd be awesome if you filled this up",
            dataType: "string",
            config: {
                multiline: true
            }
        },
        reelcrafter: {
            disabled: !["ADMIN", "INTERNAL"].some(i => roles.includes(i)) && {
                hidden: !["ADMIN", "INTERNAL"].some(i => roles.includes(i))
            },
            title: "Talent Embed",
            description: "",
            dataType: "string",
            config: {
                multiline: true
            }
        },
        countries: {
            dataType: "array",
            validation: {required: true},
            title: "Countries",
            of: {
                dataType: "reference",
                path: "countries",
                previewProperties: ["title"]
            }
        },
        languages: {
            dataType: "array",
            validation: {required: true},
            title: "Languages",
            of: {
                dataType: "reference",
                path: "languages",
                previewProperties: ["title"]
            }
        },
        picture: {
            ...imageProperty,
            validation: {required: true}
        },
        createdAt: {
            title: "Creation Date",
            dataType: "timestamp",
            autoValue: "on_create"
        },
        updatedAt: {
            title: "Last Updated",
            dataType: "timestamp",
            autoValue: "on_update",
            config: {}
        }
    },
    defaultValues: {
        status: "PENDING",
        agreement: {
            type: 'ARTIST',
            signBtn: 'ARTIST'
        }
    }
})