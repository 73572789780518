import {buildCollection} from "@camberi/firecms";
import {
    countriesSchema, creditSchema,
    genresSchema,
    instrumentsSchema,
    languagesSchema, moodsSchema,
    soundalikesSchema, trackTypesSchema
} from "../schemas/tag_schema";

export const genresCollection = buildCollection({
    path: "genres",
    schema: genresSchema,
    name: "Genres",
    group: "Tags",
    textSearchEnabled: true,
    selectionEnabled: false,
    inlineEditing: false,
    permissions: ({authController}) => ({
        edit: ["ADMIN", "INTERNAL"].some(i => authController.extra.roles.includes(i)),
        create: ["ADMIN", "INTERNAL"].some(i => authController.extra.roles.includes(i)),
        delete: ["ADMIN"].some(i => authController.extra.roles.includes(i))
    })
})

export const languagesCollection = buildCollection({
    path: "languages",
    schema: languagesSchema,
    name: "Languages",
    group: "Tags",
    textSearchEnabled: true,
    selectionEnabled: false,
    inlineEditing: false,
    permissions: ({authController}) => ({
        edit: ["ADMIN", "INTERNAL"].some(i => authController.extra.roles.includes(i)),
        create: ["ADMIN", "INTERNAL"].some(i => authController.extra.roles.includes(i)),
        delete: ["ADMIN"].some(i => authController.extra.roles.includes(i))
    })
})

export const countriesCollection = buildCollection({
    path: "countries",
    schema: countriesSchema,
    name: "Countries",
    group: "Tags",
    textSearchEnabled: true,
    selectionEnabled: false,
    inlineEditing: false,
    permissions: ({authController}) => ({
        edit: ["ADMIN", "INTERNAL"].some(i => authController.extra.roles.includes(i)),
        create: ["ADMIN", "INTERNAL"].some(i => authController.extra.roles.includes(i)),
        delete: ["ADMIN"].some(i => authController.extra.roles.includes(i))
    })
})

export const soundalikesCollection = buildCollection({
    path: "soundalikes",
    schema: soundalikesSchema,
    name: "Soundalikes",
    group: "Tags",
    textSearchEnabled: true,
    selectionEnabled: false,
    inlineEditing: false,
    permissions: ({authController}) => ({
        edit: ["ADMIN", "INTERNAL"].some(i => authController.extra.roles.includes(i)),
        create: ["ADMIN", "INTERNAL"].some(i => authController.extra.roles.includes(i)),
        delete: ["ADMIN"].some(i => authController.extra.roles.includes(i))
    })
})

export const instrumentsCollection = buildCollection({
    path: "instruments",
    schema: instrumentsSchema,
    name: "Instruments",
    group: "Tags",
    textSearchEnabled: true,
    selectionEnabled: false,
    inlineEditing: false,
    permissions: ({authController}) => ({
        edit: ["ADMIN", "INTERNAL"].some(i => authController.extra.roles.includes(i)),
        create: ["ADMIN", "INTERNAL"].some(i => authController.extra.roles.includes(i)),
        delete: ["ADMIN"].some(i => authController.extra.roles.includes(i))
    })
})

export const moodsCollection = buildCollection({
    path: "moods",
    schema: moodsSchema,
    name: "Moods",
    group: "Tags",
    textSearchEnabled: true,
    selectionEnabled: false,
    inlineEditing: false,
    permissions: ({authController}) => ({
        edit: ["ADMIN", "INTERNAL"].some(i => authController.extra.roles.includes(i)),
        create: ["ADMIN", "INTERNAL"].some(i => authController.extra.roles.includes(i)),
        delete: ["ADMIN"].some(i => authController.extra.roles.includes(i))
    })
})

export const creditsCollection = buildCollection({
    path: "credits",
    schema: creditSchema,
    name: "Credits",
    group: "Tags",
    textSearchEnabled: true,
    selectionEnabled: false,
    inlineEditing: false,
    permissions: ({authController}) => ({
        edit: ["ADMIN", "INTERNAL"].some(i => authController.extra.roles.includes(i)),
        create: ["ADMIN", "INTERNAL"].some(i => authController.extra.roles.includes(i)),
        delete: ["ADMIN"].some(i => authController.extra.roles.includes(i))
    })
})

export const trackTypesCollection = buildCollection({
    path: "trackTypes",
    schema: trackTypesSchema,
    name: "Track Types",
    group: "Tags",
    selectionEnabled: false,
    inlineEditing: false,
    permissions: ({authController}) => ({
        edit: ["ADMIN", "INTERNAL"].some(i => authController.extra.roles.includes(i)),
        create: ["ADMIN", "INTERNAL"].some(i => authController.extra.roles.includes(i)),
        delete: ["ADMIN"].some(i => authController.extra.roles.includes(i))
    })
})