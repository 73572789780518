import {buildCollection} from "@camberi/firecms";
import {profilesSchema} from "../schemas/profile_schema";
import {defaultCollectionCallbacks} from "../utils/collection_utils";

export const profilesCollection = ({roles}) => buildCollection({
    path: "profiles",
    schema: profilesSchema({roles}),
    name: "Profiles",
    group: "Library",
    textSearchEnabled: true,
    selectionEnabled: false,
    //extraActions: ProfileExtraActionBuilder,
    callbacks: defaultCollectionCallbacks,
    inlineEditing: false,
    properties: ["ADMIN", "INTERNAL"].some(i => roles.includes(i)) ? false : ["title"],
    excludedProperties: ["agreement"],
    permissions: ({authController}) => ({
        edit: ["ADMIN", "INTERNAL"].some(i => authController.extra.roles.includes(i)),
        create: ["ADMIN", "INTERNAL"].some(i => authController.extra.roles.includes(i)),
        delete: ["ADMIN", "INTERNAL"].some(i => authController.extra.roles.includes(i))
    })
})
