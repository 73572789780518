import {buildCollection} from "@camberi/firecms";
import {usersSchema} from "../schemas/users_schema";
import {UserExtraActionBuilder} from "../actions/user_actions";

export const usersCollection = buildCollection({
    path: "users",
    schema: usersSchema,
    name: "Users",
    group: "Accounts",
    //extraActions: UserExtraActionBuilder,
    selectionEnabled: false,
    inlineEditing: false,
    permissions: ({authController}) => ({
        edit: ["ADMIN", "INTERNAL"].some(i => authController.extra.roles.includes(i)),
        create: ["ADMIN", "INTERNAL"].some(i => authController.extra.roles.includes(i)),
        delete: ["ADMIN"].some(i => authController.extra.roles.includes(i))
    })
})
