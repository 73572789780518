import AudioPreview from "../previews/AudioPreview";
import {UserUploadField} from "../fields/UserUploadField";
import {GetFireCMSContext} from "../utils/db_utils";

export const AudioProperty = ({entityId, path, values, previousValues}) => {
    return {
        title: "Audio",
        dataType: "map",
        disabled: false,
        properties: {
            wav: {
                dataType: "map",
                config: {
                    Preview: x => AudioPreview({
                        ...x,
                        fileName: values && values.title
                    }),
                },
                properties: {
                    downloadURL: {
                        title: "Published audio (if any)",
                        dataType: "string",
                        readOnly: true,
                        config: {
                            Preview: ({value}) => AudioPreview({
                                value: {downloadURL: value},
                                fileName: values && values.title
                            })
                        }
                    },
                    tmpRef: {
                        title: "Upload New WAV below:",
                        dataType: "string",
                        validation: {required: !entityId},
                        config: {
                            Field: UserUploadField,
                            storageMeta: {
                                metadata: {
                                    'storageType': "audio/wav"
                                },
                                acceptedFiles: ["audio/wav"]
                            }
                        }
                    }
                }
            }
        }
    }
}