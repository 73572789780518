import {buildSchema} from "@camberi/firecms";

export const genresSchema = buildSchema({
    name: "Genre",
    properties: {
        title: {
            title: "Title",
            validation: {required: true},
            dataType: "string"
        },
    }
})

export const languagesSchema = buildSchema({
    name: "Language",
    properties: {
        title: {
            title: "Title",
            validation: {required: true},
            dataType: "string"
        },
    }
})

export const countriesSchema = buildSchema({
    name: "Country",
    properties: {
        title: {
            title: "Title",
            validation: {required: true},
            dataType: "string"
        },
    }
})


export const soundalikesSchema = buildSchema({
    name: "Soundalike",
    properties: {
        title: {
            title: "Title",
            validation: {required: true},
            dataType: "string"
        },
    }
})

export const instrumentsSchema = buildSchema({
    name: "Instrument",
    properties: {
        title: {
            title: "Title",
            validation: {required: true},
            dataType: "string"
        },
    }
})

export const moodsSchema = buildSchema({
    name: "Mood",
    properties: {
        title: {
            title: "Title",
            validation: {required: true},
            dataType: "string"
        },
    }
})


export const creditSchema = buildSchema({
    name: "Credit",
    properties: {
        title: {
            title: "Title",
            validation: {required: true},
            dataType: "string"
        },
    }
})

export const trackTypesSchema = buildSchema({
    name: "Track Type",
    properties: {
        title: {
            title: "Title",
            validation: {required: true},
            dataType: "string"
        },
    }
})

export const trackVersionsSchema = buildSchema({
    name: "Track Version",
    properties: {
        title: {
            title: "Title",
            validation: {required: true},
            dataType: "string"
        },
    }
})