import {buildCollection} from "@camberi/firecms";
import {documentSchema} from "../schemas/document_schema";
import {DocumentExtraActionBuilder} from "../actions/document_actions";
import {defaultCollectionCallbacks} from "../utils/collection_utils";

export const documentsCollection = buildCollection({
    path: "documents",
    schema: documentSchema,
    group: "Library",
    name: "Documents",
    selectionEnabled: false,
    //extraActions: DocumentExtraActionBuilder,
    textSearchEnabled: true,
    inlineEditing: false,
    callbacks: defaultCollectionCallbacks,
    permissions: ({authController}) => ({
        edit: ["ADMIN", "INTERNAL"].some(i => authController.extra.roles.includes(i)),
        create: ["ADMIN", "INTERNAL"].some(i => authController.extra.roles.includes(i)),
        delete: ["ADMIN"].some(i => authController.extra.roles.includes(i))
    })
})


