import {buildCollection} from "@camberi/firecms";
import {directorySchema} from "../schemas/directory_schema";
import {defaultCollectionCallbacks} from "../utils/collection_utils";
import {DirectoryExtraActionBuilder} from "../actions/directory_actions";

export const directoryCollection = ({roles}) => buildCollection({
    path: "proaccounts",
    schema: directorySchema,
    name: "PRO Accounts",
    group: "Library",
    textSearchEnabled: true,
    //extraActions: DirectoryExtraActionBuilder,
    selectionEnabled: false,
    callbacks: defaultCollectionCallbacks,
    inlineEditing: false,
    properties: ["ADMIN", "INTERNAL"].some(i => roles.includes(i)) ? false : ["title", "usage", "ipi"],
    permissions: ({authController}) => ({
        edit: ["ADMIN", "INTERNAL"].some(i => authController.extra.roles.includes(i)),
        create: ["ADMIN", "INTERNAL"].some(i => authController.extra.roles.includes(i)),
        delete: ["ADMIN"].some(i => authController.extra.roles.includes(i))
    })
})

