import {DrawerProps, useAuthController, useNavigation} from "@camberi/firecms";
import {useStyles} from "./CustomHomePage";
import {computeTopNavigation, TopNavigationEntry, TopNavigationResult} from "./og/core/util/navigation_utils";
import React, {useCallback, useMemo} from "react";
import {Box, Divider, Link, List, ListItem, Typography} from "@mui/material";
import {NavLink} from "react-router-dom";
import logo from "./images/ChaiTime_Logo_lg.png";

export function CustomFireCMSDrawer({
                                        closeDrawer
                                    }: DrawerProps) {

    const classes = useStyles();

    const navigationContext = useNavigation();

    const {
        navigationEntries,
        groups
    }: TopNavigationResult = useMemo(() => computeTopNavigation(navigationContext, false), [navigationContext]);
    const authContext = useAuthController();

    const roles = authContext.extra.roles
    const fGroups = ["ADMIN", "INTERNAL"].some(i => roles.includes(i)) ? groups : ["My Stuff"]

    const ungroupedNavigationViews = Object.values(navigationEntries).filter(e => !e.group);

    const createNavigationListItem = useCallback((index: number, group: string, entry: TopNavigationEntry) =>
        <ListItem
            // @ts-ignore
            button
            key={`navigation_${index}`}
            component={NavLink}
            onClick={closeDrawer}
            // @ts-ignore
            style={({isActive}) => ({
                fontWeight: isActive ? "600" : "500",
                background: isActive ? "rgba(128,128,128,0.1)" : "inherit"
            })}
            to={entry.url}
        >
            <Typography
                variant={"subtitle2"}
                sx={{
                    fontWeight: "inherit",
                    py: 0.5
                }}>
                {entry.name.toUpperCase()}
            </Typography>
        </ListItem>, [closeDrawer]);

    let logoComponent;
    if (logo) {
        logoComponent = <img className={classes.logo}
                             src={logo}
                             alt={"Logo"}/>;
    } else {
        logoComponent = <div className={classes.logo}>

        </div>;
    }

    return <>

        <Link
            key={"breadcrumb-home"}
            color="inherit"
            onClick={closeDrawer}
            component={NavLink}

            to={navigationContext.homeUrl}>
            {logoComponent}
        </Link>

        <List>

            {fGroups.map((group) => (
                <React.Fragment
                    key={`drawer_group_${group}`}>
                    <Divider key={`divider_${group}`}/>
                    <Box pt={2} pl={2} pr={2} pb={0.5}>
                        <Typography variant={"caption"}
                                    color={"textSecondary"}
                                    className={"weight-500"}>
                            {group.toUpperCase()}
                        </Typography>
                    </Box>
                    {Object.values(navigationEntries)
                        .filter(e => e.group === group)
                        .map((view, index) => createNavigationListItem(index, group, view))}
                </React.Fragment>
            ))}

            {ungroupedNavigationViews.length > 0 &&
                <Divider key={"divider_ungrouped"}/>}

            {ungroupedNavigationViews.map((view, index) => createNavigationListItem(index, "none", view))}

        </List>

    </>;
}