import {CssBaseline, ThemeProvider, Box, Typography, Modal} from "@mui/material";
import {BrowserRouter} from "react-router-dom";

import "typeface-rubik";
import "typeface-space-mono";

import {User as FirebaseUser, getAuth, connectAuthEmulator, IdTokenResult} from "firebase/auth";
import {getFirestore, connectFirestoreEmulator} from "firebase/firestore";
import {getStorage, connectStorageEmulator} from "firebase/storage";
import {
    Authenticator,
    CircularProgressCenter,
    createCMSDefaultTheme,
    FireCMS,
    NavigationBuilder,
    NavigationBuilderProps,
    NavigationRoutes,
    Scaffold,
    SideEntityDialogs,
    useFirebaseAuthDelegate,
    useFirebaseStorageSource,
    useFirestoreDataSource,
    FirebaseAuthDelegate,
    Navigation,
    EntityCollection
    , Entity
} from "@camberi/firecms";
import {Tooltip, IconButton} from "@mui/material";
import {Home} from "@mui/icons-material";
import {firebaseConfig} from "../firebase_config";
import logo from "./images/ChaiTime_Logo_lg.png";
import {textSearchController} from "./text_search";
import {
    languagesCollection,
    genresCollection,
    countriesCollection,
    soundalikesCollection,
    instrumentsCollection,
    moodsCollection,
    creditsCollection,
    trackTypesCollection
} from "./collections/tag_collection";
import {tracksCollection} from "./collections/tracks_collection";
import "typeface-rubik";
import "typeface-space-mono";
import {profilesCollection} from "./collections/profile_collection";
import {usersCollection} from "./collections/users_collection";
import {documentsCollection} from "./collections/documents_collection";
import './styles/main.scss';
import {directoryCollection} from "./collections/directory_collection";
import {CustomTableView} from "./CustomTableView";
import {usersSchema} from "./schemas/users_schema";
import {TrackSchema} from "./schemas/track_schema";
import {profilesSchema} from "./schemas/profile_schema";
import {documentSchema} from "./schemas/document_schema";
import {directorySchema} from "./schemas/directory_schema";
import {CustomHomePage, useStyles} from "./CustomHomePage";
import {CustomFireCMSDrawer} from "./CustomFireCMSDrawer";
import {FirebaseLoginView, useInitialiseFirebase} from "./og";
import {faqCollection} from "./collections/faq_collection";
import React from "react";
import {set} from "nuxt/dist/app/compat/capi";
import {MultiTrackUploader} from "./components/MultiTrackUpload";

function CMS() {
    const [openModal, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const modalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        background: '#000',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const siteLink = (
        <Tooltip
            title="Back to site">
            <IconButton
                href={process.env.NODE_ENV === "development" ? "http://localhost:4500/" : "https://chaitimestudios.com/"}
                target="_blank"
                component={"a"}
                size="large">
                <Home/>
            </IconButton>
        </Tooltip>
    );

    const signInOptions = [
        'password',
        'google.com'
    ]

    const onFirebaseInit = (config: Object) => {
        if (process.env.NODE_ENV === "development") {
            const db = getFirestore();
            const storage = getStorage();
            const auth = getAuth();

            try {
                connectAuthEmulator(auth, "http://localhost:9099");
                connectFirestoreEmulator(db, 'localhost', 8080);
                connectStorageEmulator(storage, "localhost", 9199);
            } catch (e) {
                console.error(e)
            }
        } else {
            console.log("Starting Prod Site")
        }
    };

    const {
        firebaseApp,
        firebaseConfigLoading,
        configError,
        firebaseConfigError
    } = useInitialiseFirebase({firebaseConfig, onFirebaseInit});

    const [myTracks, setMyTracks] = React.useState<Entity<M>[]>([]);
    const [myProfiles, setMyProfiles] = React.useState<Entity<M>[]>([]);
    const [myDocuments, setMyDocuments] = React.useState<Entity<M>[]>([]);
    const [myProaccounts, setMyProaccounts] = React.useState<Entity<M>[]>([]);

    const authDelegate: FirebaseAuthDelegate = useFirebaseAuthDelegate({
        firebaseApp,
        signInOptions
    });

    const dataSource = useFirestoreDataSource({
        firebaseApp: firebaseApp,
        textSearchController
    });
    const storageSource = useFirebaseStorageSource({firebaseApp: firebaseApp});

    if (configError) {
        return <div> {configError} </div>;
    }

    if (firebaseConfigError) {
        return <div>
            It seems like the provided Firebase config is not correct. If you
            are using the credentials provided automatically by Firebase
            Hosting, make sure you link your Firebase app to Firebase
            Hosting.
        </div>;
    }

    if (firebaseConfigLoading || !firebaseApp) {
        return <>
            <CssBaseline/>
            <CircularProgressCenter/>
        </>;
    }


    let firstUserLoad = true
    const onUserUpdate = async (fUser, roles) => {
        handleOpen()

        //await new Promise(r => setTimeout(r, 2000));

        const resolveUserEntities = async (collection: string, schema: EntitySchema) => {
            let entities = []
            if (fUser && fUser.values[collection]) {
                for (const doc of fUser.values[collection]) {
                    const fEntity = await dataSource.fetchEntity({
                        path: doc.path,
                        entityId: doc.id,
                        schema: schema
                    })

                    if (fEntity.values) {
                        entities.push(fEntity)
                    }
                }
            }

            return entities
        }

        setMyTracks(await resolveUserEntities('tracks', TrackSchema({roles: roles})))
        setMyProfiles(await resolveUserEntities('profiles', profilesSchema({roles: roles})))
        setMyDocuments(await resolveUserEntities('documents', documentSchema))
        setMyProaccounts(await resolveUserEntities('proaccounts', directorySchema))

        handleClose()
        firstUserLoad = false
    }

    const myAuthenticator: Authenticator<FirebaseUser> = async ({
                                                                    user,
                                                                    authController,
                                                                    dataSource
                                                                }) => {

        const udata: IdTokenResult = await user?.getIdTokenResult()

        const sampleUserData = {
            storageDir: `users/${user?.uid}`,
            roles: udata && udata.claims && udata.claims.roles ? udata.claims.roles : [],
        };

        authController.setExtra(sampleUserData);

        dataSource.listenEntity({
            path: 'users',
            entityId: user?.uid,
            schema: usersSchema,
            onUpdate: (user) => onUserUpdate(user, sampleUserData['roles'])
        })

        return true;
    };

    const navigation: NavigationBuilder<FirebaseUser> = async ({
                                                                   user,
                                                                   authController,
                                                                   dataSource
                                                               }: NavigationBuilderProps) => {

        if (!user || !user?.uid) {
            return {}
        }

        const roles = authController.extra.roles

        const tracksCol: EntityCollection = tracksCollection({roles})
        const profilesCol: EntityCollection = profilesCollection({roles})
        const directoryCol: EntityCollection = directoryCollection({roles})
        const faqCol: EntityCollection = faqCollection({roles})

        let navigation: Navigation = {
            collections: [
                directoryCol,
                documentsCollection,
                usersCollection,
                tracksCol,
                profilesCol,
                faqCol,
                languagesCollection,
                genresCollection,
                countriesCollection,
                soundalikesCollection,
                instrumentsCollection,
                moodsCollection,
                creditsCollection,
                trackTypesCollection,
            ]
        }


        try {
            const customViews: CMSView[] = [
                {
                    path: ["mytracks", "mytracks/:id"],
                    name: "My Tracks",
                    group: "My Stuff",
                    view: <CustomTableView collection={{
                        ...tracksCol,
                        properties: false,
                        textSearchEnabled: false,
                        permissions: ({authController}) => ({
                            edit: ["ADMIN", "INTERNAL", "ARTIST"].some(i => authController.extra.roles.includes(i)),
                            create: ["ADMIN", "INTERNAL", "ARTIST"].some(i => authController.extra.roles.includes(i)),
                            delete: ["ADMIN", "INTERNAL", "ARTIST"].some(i => authController.extra.roles.includes(i))
                        })
                    }} myEntities={myTracks}/>
                },
                {
                    path: ["myprofiles", "myprofiles/:id"],
                    name: "My Profiles",
                    group: "My Stuff",
                    view: <CustomTableView collection={{
                        ...profilesCol,
                        properties: false,
                        textSearchEnabled: false,
                        permissions: ({authController}) => ({
                            edit: ["ADMIN", "INTERNAL", "ARTIST"].some(i => authController.extra.roles.includes(i)),
                            create: ["ADMIN", "INTERNAL", "ARTIST"].some(i => authController.extra.roles.includes(i)),
                            delete: ["ADMIN", "INTERNAL", "ARTIST"].some(i => authController.extra.roles.includes(i))
                        })
                    }} myEntities={myProfiles}/>
                },
                {
                    path: ["myproaccounts", "myproaccounts/:id"],
                    name: "My PRO Accounts",
                    group: "My Stuff",
                    view: <CustomTableView collection={{
                        ...directoryCol,
                        properties: false,
                        textSearchEnabled: false,
                        permissions: ({authController}) => ({
                            edit: ["ADMIN", "INTERNAL", "ARTIST"].some(i => authController.extra.roles.includes(i)),
                            create: ["ADMIN", "INTERNAL", "ARTIST"].some(i => authController.extra.roles.includes(i)),
                            delete: ["ADMIN"].some(i => authController.extra.roles.includes(i))
                        })
                    }} myEntities={myProaccounts}/>
                },
                {
                    path: ["mydocuments", "mydocuments/:id"],
                    name: "My Documents",
                    group: "My Stuff",
                    view: <CustomTableView collection={{
                        ...documentsCollection,
                        properties: false,
                        textSearchEnabled: false,
                    }} myEntities={myDocuments}/>
                }
            ];

            navigation = {...navigation, views: customViews};
        } catch (e) {
            console.error(e)
        }

        return navigation
    };

    return (
        <BrowserRouter>
            <FireCMS navigation={navigation}
                     authentication={myAuthenticator}
                     authDelegate={authDelegate}
                     dataSource={dataSource}
                     storageSource={storageSource}
                     entityLinkBuilder={({entity}) => `https://console.firebase.google.com/project/${firebaseApp.options.projectId}/firestore/data/${entity.path}/${entity.id}`}
            >
                {({context, mode, loading}) => {

                    const theme = createCMSDefaultTheme({
                        mode,
                        primaryColor: "#d12020"
                    });

                    let component;
                    if (loading) {
                        component = <CircularProgressCenter/>;
                    } else if (!context.authController.canAccessMainView) {
                        component = (
                            <>
                                <FirebaseLoginView
                                    skipLoginButtonEnabled={false}
                                    signInOptions={signInOptions}
                                    logo={logo}
                                    NoUserComponent={<>User not allowed or not exists</>}
                                    disableSignupScreen={true}
                                    allowSkipLogin={false}
                                    firebaseApp={firebaseApp}
                                    authDelegate={authDelegate}/>
                            </>
                        );
                    } else {
                component = (
                    <Scaffold
                        name={"Chai Time CMS"}
                        Drawer={CustomFireCMSDrawer}
                        toolbarExtraWidget={siteLink}>
                        <NavigationRoutes
                            HomePage={CustomHomePage}/>
                        <SideEntityDialogs/>
                    </Scaffold>
                );
            }

                return (
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    {component}
                </ThemeProvider>
                );
                }}
            </FireCMS>
            <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Loading...
                    </Typography>
                </Box>
            </Modal>
        </BrowserRouter>
    );
}

    export default CMS;

