import * as React from "react";
import {DropzoneDialog} from 'react-mui-dropzone'

import isEqual from "react-fast-compare";
import {
    Box,
    FormHelperText,
    IconButton,
    Paper,
    Theme,
    Typography
} from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import makeStyles from "@mui/styles/makeStyles";

import {
    ArrayProperty,
    FieldProps,
    Property,
    StorageMeta,
    StringProperty,
    PreviewSize,
    FieldDescription,
    LabelWithIcon,
    useClearRestoreValue,
    useSnackbarController,
    useStorageSource, useAuthController, useDataSource,
} from "@camberi/firecms";

import {getRandomId} from "../utils/db_utils";
import LoadingButton from "@mui/lab/LoadingButton";
import {
    Button,
    FormControl, FormGroup,
} from "@mui/material";

type StorageUploadFieldProps = FieldProps<string | string[]>;

/**
 * Field that allows to upload files to Google Cloud Storage.
 *
 * This is one of the internal components that get mapped natively inside forms
 * and tables to the specified properties.
 * @category Form fields
 */
export function UserUploadField({
                                    name,
                                    value,
                                    setValue,
                                    error,
                                    showError,
                                    autoFocus,
                                    tableMode,
                                    property,
                                    includeDescription,
                                    context,
                                    isSubmitting
                                }: StorageUploadFieldProps) {

    const [loading, setLoading] = React.useState<boolean>(false);

    const multipleFilesSupported = property.dataType === "array";
    const disabled = !!property.disabled || isSubmitting;

    const internalValue = multipleFilesSupported
        ? (Array.isArray(value) ? value : [])
        : value;

    useClearRestoreValue<string | string[]>({
        property,
        value,
        setValue
    });

    const storageMeta: StorageMeta | undefined = property.dataType === "string"
        ? property.config?.storageMeta
        : property.dataType === "array" &&
        (property.of as Property).dataType === "string"
            ? (property.of as StringProperty).config?.storageMeta
            : undefined;

    if (!storageMeta)
        throw Error("Storage meta must be specified");

    return (

        <FormControl fullWidth
                     required={property.validation?.required}
                     error={showError}>

            {!tableMode &&
                <FormHelperText filled
                                required={property.validation?.required}>
                    <LabelWithIcon property={property}/>
                </FormHelperText>}

            <StorageUpload
                loading={loading}
                setLoading={setLoading}
                value={internalValue}
                name={name}
                disabled={disabled}
                autoFocus={autoFocus}
                property={property}
                onChange={(newValue) => {
                    setValue(newValue);
                }}
                storageMeta={storageMeta}
                multipleFilesSupported={multipleFilesSupported}/>

            <ol>
                {
                    (loading ? (<LoadingButton style={{
                        display: loading ? "flex" : "none"
                    }} loading={loading}
                                               disabled={true}/>) : multipleFilesSupported ? (internalValue && internalValue.map((entry, index) => entry && entry.storagePathOrDownloadUrl && (
                        <li key={index}>
                            File Ready
                        </li>
                    ))) : internalValue && (<li>File Ready</li>))
                }
            </ol>


            {includeDescription &&
                <FieldDescription property={property as any}/>}

            {showError && <FormHelperText>{error}</FormHelperText>}

        </FormControl>
    );
}

/**
 * Internal representation of an item in the storage
 * It can have two states, having a storagePathOrDownloadUrl set,
 * which means the file has
 * been uploaded and it is rendered as a preview
 * Or have a pending file being uploaded.
 */
interface StorageFieldItem {
    id: number; // generated on the fly for internal use only
    storagePathOrDownloadUrl?: string;
    file?: File;
    fileName?: string;
    metadata?: any,
    size: PreviewSize
}

interface StorageUploadProps {
    value: string | string[];
    name: string;
    property: StringProperty | ArrayProperty<string[]>;
    onChange: (value: string | string[] | null) => void;
    multipleFilesSupported: boolean;
    autoFocus: boolean;
    disabled: boolean;
    storageMeta: StorageMeta;
}

export function StorageUpload({
                                  loading,
                                  setLoading,
                                  property,
                                  name,
                                  value,
                                  onChange,
                                  multipleFilesSupported,
                                  disabled,
                                  autoFocus,
                                  storageMeta
                              }: StorageUploadProps) {

    const snackbar = useSnackbarController()
    const authController = useAuthController()
    const storage = useStorageSource();

    if (multipleFilesSupported) {
        const arrayProperty = property as ArrayProperty<string[]>;
        if (arrayProperty.of) {
            if (arrayProperty.of.dataType !== "string") {
                throw Error("Storage field using array must be of data type string");
            }
        } else {
            throw Error("Storage field using array must be of data type string");
        }
    }

    const metadata: any | undefined = storageMeta?.metadata;

    const size = multipleFilesSupported ? "small" : "regular";

    const internalInitialValue: StorageFieldItem[] =
        (multipleFilesSupported
            ? value as string[]
            : [value as string]).map(entry => (
            {
                id: getRandomId(),
                storagePathOrDownloadUrl: entry,
                metadata: metadata,
                size: size
            }
        ));

    const [dialogueOpen, setDialogueOpen] = React.useState<boolean>(false);
    const [initialValue, setInitialValue] = React.useState<string | string[]>(value);
    const [internalValue, setInternalValue] = React.useState<StorageFieldItem[]>(internalInitialValue);
    const [total, setTotal] = React.useState<number>(0);
    const [uploaded, setUploaded] = React.useState<number>(0);

    if (!isEqual(initialValue, value)) {
        setInitialValue(value);
        setInternalValue(internalInitialValue);
    }

    let upload = async (newInternalValue) => {
        setTotal(newInternalValue.length)
        setUploaded(0)
        let i = 0
        for (let entry of newInternalValue) {
            i++
            if (entry.file) {
                let file = entry.file
                let orgName = entry.file?.name

                if (orgName && orgName.length > 1) {
                    orgName = orgName.replace("-_-", "")

                    if (orgName.length > 1) {
                        orgName = orgName.replace(/\.[^/.]+$/, "")
                    }
                }

                entry.fileName = orgName ? (entry.id.toString() + "-_-" + orgName) : entry.id.toString()
                metadata['customMetadata'] = {
                    'pendingFirestoreReg': true,
                    'lifespam': 86400
                }

                let result;

                try {
                    result = await storage.uploadFile({
                        file,
                        fileName: entry.fileName,
                        path: `${authController.extra.storageDir}/tmp`,
                        metadata
                    })
                } catch (e) {
                    console.error("Upload error", e);

                    snackbar.open({
                        type: "error",
                        title: "Error uploading file",
                        message: e.message
                    });
                    return;
                }

                setUploaded(i)

                entry.storagePathOrDownloadUrl = result['path'];
                entry.metadata = metadata;
                entry.file = null

                snackbar.open({
                    message: `${orgName} uploaded.`
                });
            }
        }

        return newInternalValue
    }


    const onFilesSubmitted = async (acceptedFiles: File[]) => {
        setLoading(true)

        setDialogueOpen(false)

        if (!acceptedFiles.length || disabled)
            return;

        let newInternalValue: StorageFieldItem[];
        if (multipleFilesSupported) {
            newInternalValue = [...internalValue,
                ...(acceptedFiles.map(file => ({
                    id: getRandomId(),
                    file,
                    metadata,
                    size: size
                } as StorageFieldItem)))];
        } else {
            newInternalValue = [{
                id: getRandomId(),
                file: acceptedFiles[0],
                metadata,
                size: size
            }];
        }

        setInternalValue(await upload(newInternalValue))

        let readyFiles = newInternalValue.filter(v => !!v.storagePathOrDownloadUrl).map(v => v.storagePathOrDownloadUrl as string)

        if (multipleFilesSupported) {
            onChange(readyFiles);
        } else {
            onChange(readyFiles.length > 0 && readyFiles[0]);
        }

        setLoading(false)
    };

    const onClear = (clearedStoragePathOrDownloadUrl: string) => {
        if (multipleFilesSupported) {
            const newValue: StorageFieldItem[] = internalValue.filter(v => v.storagePathOrDownloadUrl !== clearedStoragePathOrDownloadUrl);
            onChange(newValue.filter(v => !!v.storagePathOrDownloadUrl).map(v => v.storagePathOrDownloadUrl as string));
            setInternalValue(newValue);
        } else {
            onChange(null);
            setInternalValue([]);
        }
    };

    const onOpen = () => {
        setDialogueOpen(true)
    }

    const onClose = () => {
        setDialogueOpen(false)
    }

    return (
        <>


            {
                (loading ? (
                    <LoadingButton
                        style={{
                            marginTop: "1rem",
                        }}
                        loading
                        loadingPosition="start"
                        startIcon={<CloudUploadIcon/>}
                        variant="outlined"
                    >
                        {uploaded}/{total} Uploaded
                    </LoadingButton>
                ) : (<Button
                    style={{marginTop: "1rem"}}
                    variant="contained"
                    size="large"
                    endIcon={<CloudUploadIcon/>}
                    disabled={disabled || loading} onClick={onOpen}>Add New Files</Button>))
            }

            <DropzoneDialog
                filesLimit={multipleFilesSupported ? 200 : 1}
                open={dialogueOpen}
                onSave={onFilesSubmitted}
                acceptedFiles={storageMeta.acceptedFiles}
                showPreviews={true}
                maxFileSize={5000000000}
                onClose={onClose}
            />
        </>
    );

}