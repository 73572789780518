import {PreviewComponentProps} from "@camberi/firecms";
import * as React from "react";

export default function ImagePreview({value}: PreviewComponentProps<string>) {

    const imgStyle = {
        height: "64px",
        padding: "5px",
        margin: "auto",
        display: "flex",
        borderRadius: "10px"
    };

    return (
        value &&
        (value.downloadURL || value.tmpDownloadUrl) &&
        <img style={imgStyle}
             src={`${value.tmpDownloadUrl ? value.tmpDownloadUrl : value.downloadURL}?v=${value.updatedAt ? value.updatedAt.getTime() : Date.now()}`}/>
    );
}