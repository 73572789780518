import {buildEntityCallbacks} from "@camberi/firecms";

export const defaultCollectionCallbacks = buildEntityCallbacks({
    onPreSave: ({context, values}) => {

        values.applicant = context.authController.user.uid;

        /*if(values.status && !["ADMIN", "INTERNAL"].some(i => context.authController.extra.roles.includes(i))) {
            values.status = "PENDING"
        }*/

        return values;
    }
})
