import * as React from "react";

import {
    Button,
    FormControl, FormGroup,
    FormHelperText,
} from "@mui/material";

import {
    ArrayProperty,
    FieldProps,
    Property,
    StringProperty,
    PreviewSize,
    FieldDescription,
    LabelWithIcon,
    useClearRestoreValue,
    useSnackbarController,
    useAuthController, useDataSource,
} from "@camberi/firecms";

import {StorageUpload} from "../fields/UserUploadField";
import {TrackSchema} from "../schemas/track_schema";
import {serverTimestamp} from "firebase/firestore";

export function MultiTrackUploader({
                                       loading,
                                       setLoading
                                   }) {

    let name = "MultiTrackUploader"
    let snackbar = useSnackbarController()
    let authController = useAuthController()
    let dataSource = useDataSource()

    const storageMeta = {
        metadata: {
            'storageType': "audio/wav"
        },
        acceptedFiles: ["audio/wav"]
    }

    const [value, setValue] = React.useState([]);

    let autoFocus = false
    let property: ArrayProperty = {
        dataType: "array",
        of: {
            dataType: "string"
        },
        disabled: false,
        validation: {
            required: false
        }
    }

    const multipleFilesSupported = true;

    const [disabled, setDisabled] = React.useState<boolean>(false);

    useClearRestoreValue<string | string[]>({
        property,
        value,
        setValue
    });

    const submitTracks = async (newTracks) => {
        setDisabled(true)

        snackbar.open({message: `Submitting ${newTracks.length} Tracks`})

        let proms = []

        for (let ref of newTracks) {
            let previewName = ref.split('/').pop()
            previewName = ref.split('-_-').pop()

            let track = {
                title: previewName,
                audio: {
                    wav: {
                        tmpRef: ref
                    }
                },
                status: "PENDING",
                applicant: authController.user.uid,
                updatedAt: serverTimestamp(),
                createdAt: serverTimestamp()
            }

            proms.push(dataSource.saveEntity({
                path: "tracks",
                values: track,
                schema: TrackSchema({roles: authController.extra.roles})
            }))
        }

        const results = await Promise.all(proms.map(p => p.catch(e => e)));
        const validResults = results.filter(result => !(result instanceof Error));

        if (results.length > validResults.length) {
            snackbar.open({type: "error", message: `${results.length - validResults.length} failed to submit :(`})
        } else {
            snackbar.open({
                title: "Success!",
                message: `${validResults.length} tracks submitted successfully! Please refresh the page if not all tracks are visible :)`
            })
        }

        setValue([])
        setDisabled(false)
    }

    return (
        <form>
            <FormControl fullWidth
                         error={false}>

                <StorageUpload
                    loading={loading}
                    setLoading={setLoading}
                    value={value}
                    name={name}
                    disabled={disabled}
                    autoFocus={autoFocus}
                    property={property}
                    onChange={(newValue) => {
                        setValue(newValue);
                        submitTracks(newValue)
                    }}
                    storageMeta={storageMeta}
                    multipleFilesSupported={multipleFilesSupported}
                />

            </FormControl>
        </form>);
}